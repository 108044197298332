var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: ["icon", `icon_${_vm.id}`, `icon_color_${_vm.color}`],
      style: _vm.size ? { "--size": `${_vm.size}px` } : {},
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [_c("use", { attrs: { href: `/static/dist/assets/icon.svg#${_vm.id}` } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }