export default {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // When click on target.
            // When click on contain target.
            // When target was deleted from document. (click on delete element in component)
            if (!(el === event.target || el.contains(event.target) || !document.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };

        // Register event after inserted element by click.
        setTimeout(() => {
            document.body.addEventListener('click', el.clickOutsideEvent);
        }, 100);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
};
