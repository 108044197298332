export default {
    state: {
        /**
         * All allowed project branches.
         */
        branches: [],

        /**
         * Selected project branch {id: Number, name: String}
         */
        branchProject: {},
    },

    getters: {
    },

    mutations: {
    },

    actions: {
        async fetchBranches({state, rootState}) {
            const {projectId} = rootState.common || {};

            return axios.get('/branch/' + projectId).then(response => {
                state.branches = response.data?.branchList || [];
                state.branchProject = response.data?.branch || {};

                return response;
            });
        },
        async setProjectBranch({state, rootState, dispatch}, {branch}) {
            const {projectId} = rootState.common || {};

            state.branchProject = branch;
            return axios.post('/branch/update/' + projectId, {id: branch.id})
                .then(response => {
                    if ('success' === response.data?.status) {
                        dispatch('fetchTopics');
                    }
                    return response;
                });
        }
    },
};
