var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preloader-wrapper" }, [
    _c("div", {
      staticClass: "spinner-border",
      style: {
        height: _vm.size,
        width: _vm.size,
      },
      attrs: { role: "status" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }