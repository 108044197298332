var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { staticClass: "radio radio_custom" },
    [
      _c("input", {
        staticClass: "radio__input",
        attrs: { type: "radio", name: _vm.inputName },
        domProps: { value: _vm.inputValue, checked: _vm.inputChecked },
        on: {
          input: function ($event) {
            _vm.$emit("inputChange", $event)
            _vm.$emit("select", _vm.item)
          },
          click: function ($event) {
            return _vm.$emit("inputSelect", $event)
          },
        },
      }),
      _vm._v(" "),
      _vm.labelPostfix
        ? [
            _c(
              "div",
              {
                directives: [
                  { name: "overflow-tip", rawName: "v-overflow-tip" },
                ],
                staticClass: "radio__label",
              },
              [
                _vm._v(_vm._s(_vm.label)),
                _c("br"),
                _vm._v(" "),
                _c("small", { staticClass: "radio__label-postfix" }, [
                  _vm._v("(" + _vm._s(_vm.labelPostfix) + ")"),
                ]),
              ]
            ),
          ]
        : [
            _c(
              "span",
              {
                directives: [
                  { name: "overflow-tip", rawName: "v-overflow-tip" },
                ],
                staticClass: "radio__label",
              },
              [
                _vm._t("label", function () {
                  return [_vm._v(_vm._s(_vm.label))]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }