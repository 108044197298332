export function isString(payload) {
    return typeof payload === 'string' || payload instanceof String;
}

/**
 * declOfNum(1, ['минута', 'минуты', 'минут']); // вернёт — минута
 * declOfNum(2, ['минута', 'минуты', 'минут']); // вернёт — минуты
 * declOfNum(5, ['минута', 'минуты', 'минут']); // вернёт — минут
 *
 * @param number
 * @param {string[]} words
 * @returns string
 */
export function declOfNum(number, words) {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
}

export function declination(number, words) {
    return number + ' ' + declOfNum(number, words);
}

export function snakeToCamelCase(str) {
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );
}

export function camelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export function randomString(length = 6, characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function generatePassword() {
    return randomString(8);
}

export function tmpId() {
    return 'tmp_' + randomString(6);
}

export function isTmpId(id) {
    return String(id).substring(0, 4) === 'tmp_';
}

export function escNumId(value) {
    return +value > 0 ? String(value) : "";
}

export function truncate(input, len = 5) {
    if (input.length > (len + 2)) {
        return input.substring(0, len) + '...';
    }

    return input;
}
