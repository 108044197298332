export default {
    state: {
        topics: [],
        topicsHr: [],
        topicsAllowed: [],
    },

    getters: {
    },

    mutations: {
    },

    actions: {
        async fetchTopics({state, rootState}) {
            const {projectId} = rootState.common || {};

            return axios.get('/topic/' + projectId).then(response => {
                state.topics = response.data.list || [];
                state.topicsHr = response.data.hr || [];
                state.topicsAllowed = response.data.add || [];

                return response;
            });
        },
        async addTopic({rootState}, payload) {
            const {projectId} = rootState.common || {};

            return axios.post('/topic/create/' + projectId, {
                topicNames: payload.topicNames || [],
                topicName: payload.topicName || '',
            });
        },
        async mergeTopics({rootState}, {sourceId, targetId}) {
            const {projectId} = rootState.common || {};

            return axios.post('/topic/save-merge/' + projectId, {sourceId, targetId});
        },
        async deleteTopic({rootState}, {id}) {
            const {projectId} = rootState.common || {};

            return axios.post('/topic/delete/' + projectId, {id});
        },
    },
};
