<template>
    <Dropdown
        class="component--select"
        :id="name"
        :disabled="isDisabled"
        :unfocusByHandler="unfocusByHandler"
        v-on:dropdown-focus="v => $emit('dropdown-focus', v)">

        <template v-slot:title>
            <slot name="title">
                <div class="placeholder placeholder_inline placeholder_icon">
                    {{ _activeLabel || placeholder }}
                    <Icon id="arrow-down" v-if="!isDisabled"></Icon>
                </div>
            </slot>
        </template>

        <div class="empty" v-if="0 === Object.keys(items || {}).length">
            <slot name="empty">{{ empty }}</slot>
        </div>
        <Scroll v-else style="max-height: 220px;">
            <RadioList
                v-if="!multiple"
                :name="name"
                :items="items"
                :active="String(active)"
                :labelCallback="labelCallback"
                v-on:radioListChange="value => $emit('selectChange', value)"
                v-on:select="value => $emit('select', value)"
                v-on:radioListSelect="radioListSelect"
            />
            <CheckboxList
                v-else
                :name="name"
                :items="items"
                :active="String(active)"
                :labelCallback="labelCallback"
                @change="values => $emit('selectChange', values)"
            />
        </Scroll>

        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>
    </Dropdown>
</template>

<script>
import selectMixin from "@common/vue/components/form/selectMixin";

/**
 * @events dropdown-focus dropdown-close selectChange
 */
export default {
    name: "Select",
    mixins: [selectMixin],
    props: {
        unfocusByHandler: {
            type: Boolean,
            default: true,
        },

        labelCallback: {
        }
    },
    methods: {
        radioListSelect(input) {
            this.$emit('selectSelect', input);
            this.closeDropdown();
        },
        closeDropdown() {
            setTimeout(() => {
                // Destroy dropdown after on change.
                busInstance.$emit('dropdown-close', this.name);
            }, 100);
        },
    }
}
</script>

<style lang="scss">
.component--select {
    .simplebar-vertical {
        right: -8px;
    }
}
</style>
