import {escObject} from "@common/utils/object";
import {escArray} from "@common/utils/array";
import {isString} from "@common/utils/text";

export default {
    name: "alertMixin",
    data() {
        return {
            alertContext: {},
            onCloseCallback: () => {},
        };
    },
    methods: {
        showSuccess(context = {}, messages = [], onClose = undefined) {
            context = escObject(context);

            this.showAlert({
                className: 'success',
                messages: messages,
                ...context,
            }, onClose);
        },
        showError(msg) {
            this.showErrors({}, Array.isArray(msg) ? msg.join('<br>\n') : msg);
        },
        showErrors(context = {}, errors = [], onClose = undefined) {
            context = escObject(context);

            this.showAlert({
                className: 'danger',
                messages: errors,
                ...context,
            }, onClose);
        },
        /**
         * @param {{title: String, messages: String|String[], timeout: Number, className: String}} context
         * @param {function} onClose
         */
        showAlert(context, onClose) {
            context = escObject(context);

            this.alertContext = {
                title: context.title || 'Ошибка',
                timeout: context.timeout || 3,
                className: context.className || 'default',
                messages: isString(context.messages) ? [context.messages] : escArray(context.messages),
            };

            if (onClose) this.onCloseCallback = onClose;
        },
        onAlertClose() {
            this.onCloseCallback(this);
            this.alertContext = {};
        },
    }
};
