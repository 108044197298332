<template>
    <div>
        <div :id="id" :class="className" role="dialog" tabindex="" aria-hidden="true">
            <div :class="'modal-dialog'+(fullMode ? ' modal-xl' : '')" role="document">
                <div class="modal-content">
                    <div class="modal-header" v-if="!fullMode && title">
                        <h5 class="w-100 text-center modal-title">{{title}}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="sticky-top modal-sticky-top" v-if="fullMode">
                            <div class="row">
                                <div class="col-2">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                        <i class="fas fa-chevron-left"></i>
                                        Назад</button>
                                </div>
                                <div class="col-10">
                                    <div class="head-bl" v-if="title">{{title}}</div>
                                </div>
                            </div>
                            <slot name="header"></slot>
                        </div>
                        <div class="flex-center">
                            <slot v-if="open" name="default"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from '@common/vue/bus';

export default {
    props: {
        title: {
            type: String
        },
        fullMode: {
            type: Boolean,
            default: true
        },
        xClassName: {
            type: String,
        }
    },
    data() {
        return {
            $modal: $(),
            id: 'modal'+Math.round(1 - 0.5 + Math.random() * (10000000 - 1 + 1)),
            className: this.getModalClass(),
            open: false,
        }
    },
    mounted() {
        this.$modal = $('#'+this.id);

        let vm = this;
        setTimeout(() => {
            $('#'+this.id).on('hidden.bs.modal', () => {
                vm.open = false;
                // bus.$emit('closeModal', true);
            });
        }, 1000);
    },
    methods: {
        getModalClass() {
            const classes = ['fade', 'modal'];

            if (this.fullMode) classes.push('full-modal');
            if (this.xClassName) classes.push(this.xClassName)

            return classes;
        },
        openModal(event) {
            if (event) event.preventDefault();

            this.open = true;
            this.$modal.modal('show');
        },
        closeModal(event) {
            this.open = false;
            this.$modal.modal('hide');
        }
    }
}
</script>

<style lang="scss">
.head-bl {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #421D57;
    letter-spacing: 0.22px;
}
.modal-sticky-top {
    padding-top:5px;background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #EBECF0;background-blend-mode: soft-light, normal
}
.full-modal {
    padding: 0 !important;
}
.full-modal .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
}
.full-modal .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}
.full-modal .modal-body {
    overflow-y: auto;
}
.full-modal .flex-center {
    min-height: 430px;
    padding-top:15px;
    margin:auto;
    overflow: auto;
    width: 95%
}
.full-modal .modal-body {
    padding-top:0
}
</style>
