var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "radio-list" }, [
    _c(
      "ul",
      { staticClass: "radio-list__list" },
      _vm._l(_vm.itemsArray, function (item) {
        return _c(
          "li",
          { key: item.id, staticClass: "radio-list__item" },
          [
            _c("Radio", {
              class: item.className,
              attrs: {
                inputName: _vm.name,
                inputValue: String(item.id),
                inputChecked: _vm.stateById[item.id].checked,
                label: _vm.labelCallback(item),
                "label-postfix": item.labelPostfix,
                item: item,
              },
              on: {
                inputChange: (e) =>
                  _vm.$emit("radioListChange", e.target.value),
                select: (item) => _vm.$emit("select", item),
                inputSelect: (e) => _vm.$emit("radioListSelect", e.target),
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }