import {isObject, toStringID} from "@common/utils/object";
import {objectToArray} from "@common/utils/array";

export function saveObjectToArray(payload, keys = []) {
    let itemsArray = (function() {
        if (isObject(payload)) {
            return objectToArray(payload);
        }

        if (Array.isArray(payload)) {
            return payload.map(toStringID);
        }

        return [];
    })();

    // Convert name to label prop.
    if (itemsArray[0]?.name !== undefined) {
        return itemsArray.map(item => ({
            ...item,
            label: item.name,
        }));
    }

    return itemsArray;
}
