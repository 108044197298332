export default {
    state: {
        regions: {},

        /**
         * Selected project regions (ids).
         */
        regionProject: [],
    },

    getters: {
    },

    mutations: {
        setByIds(state, regionIds) {
            state.regionProject = regionIds
                .map(regionId => state.regions[regionId])
                .filter(id => !!id);
        },
    },

    actions: {
        async fetchRegions({state}, {projectId}) {
            return axios.get('/region/' + projectId)
                .then(response => {
                    state.regions = response.data.regionsList || {};
                    state.regionProject = (response.data.projectRegions || [])
                        .map(regionId => state.regions[regionId]);

                    return response;
                });
        },
        async updateRegions({state, commit}, {projectId, regionIds}) {
            const saveProjectIds = [...state.regionProject];
            commit('setByIds', regionIds);

            return axios.post('/region/update/' + projectId, {regionIds})
                .catch(error => {
                    // restore state.regionProject
                    commit('setByIds', saveProjectIds);
                });
        },
    },
};
