<template>
    <div class="dropdown" :class="{ 'dropdown--open': focus }">
        <div class="dropdown__handler" @click="focus = unfocusByHandler ? !focus : true; onClick($event)">
            <slot name="title"></slot>
        </div>
        <div class="dropdown__body" v-if="(focus || forceFocus) && !disabled">
            <slot></slot>

            <div class="dropdown__actions">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import FocusMixin from "@common/vue/mixins/FocusMixin";
import CheckboxList from "@common/vue/components/form/CheckboxList";

export default {
    name: "Dropdown",
    mixins: [FocusMixin],
    components: {CheckboxList},
    props: {
        id: {
            type: String,
        },
        // Force show body.
        forceFocus: {
            type: Boolean,
            default: false,
        },
        // Force hide body.
        disabled: {
            type: Boolean,
            default: false,
        },
        unfocusByHandler: {
            type: Boolean,
            default: true,
        }
    },
    created() {
        busInstance.$on('dropdown-open', (id) => {
            if (!this.id || id === this.id) this.focus = true;
        });

        busInstance.$on('dropdown-close', (id) => {
            if (!this.id || id === this.id) this.focus = false;
        });
    },
    watch: {
        focus() {
            this.$emit('dropdown-focus', this.focus);
        }
    },
     methods: {
         onClick(event) {
             if (event.target.className.includes('tip-message')) {
                const tooltips = $('.tooltip');
                Object.values(tooltips).forEach((el) => {
                    if (el.id?.includes('tooltip')) el.remove();
                });
             }
         }
     }
}
</script>
