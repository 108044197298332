import {isObject} from "@common/utils/object";

/**
 * @param {Object[]} payload
 * @param id
 * @return {Object[]}
 */
export function deleteById(payload, id) {
    let result = [...escArray(payload)];

    const idx = result.findIndex(x => x.id === id);
    if (idx >= 0) result.splice(idx, 1);

    return result;
}

/**
 * @param {Array} payload
 */
export function escArray(payload) {
    return Array.isArray(payload) ? payload : [];
}

/**
 * Convert associative array (object) with index item ID
 *
 * @param {Object} payload
 * @param {String} valueKey в какой ключ записывать значение, если элементы списка не объект.
 * @return {Object[]}
 */
export function objectToArray(payload, valueKey = 'label') {
    if (!isObject(payload)) return [];

    return Object.entries(payload).map(([id, item]) => {
        return isObject(item) ?
            {...item, id} :
            {id, [valueKey]: item};
    });
}

export function unique(payload) {
    return Array.from(new Set(payload));
}
