import {isString} from "@common/utils/text";

export default {
    name: "selectMixin",
    props: {
        /**
         * Unique input name.
         */
        name: {
            type: String,
            required: true,
        },

        /**
         * No selected message.
         */
        placeholder: {
            type: String,
            default: 'Не выбрано',
        },

        /**
         * No items message.
         */
        empty: {
            type: String,
            default: 'Ничего не найдено',
        },

        /**
         * Checkbox or Radio list.
         */
        multiple: {
            type: Boolean,
            default: false,
        },

        /**
         * RadioList or CheckboxList items.
         */
        items: {
            type: Array | Object,
            required: true,
        },

        /**
         * Selected item ID.
         */
        active: {
            type: String | Number,
        },

        labelCallback: {
            // type: Callable,
        },
    },
    computed: {
        _activeLabel() {
            if (this.activeLabel) return this.activeLabel;

            const activeItem = this.searchById(this.active);

            if (activeItem) {
                if (this.labelCallback) {
                    return this.labelCallback(activeItem);
                }

                if (isString(activeItem)) return activeItem;

                const postfix = activeItem.labelPostfix ? ` (${activeItem.labelPostfix})` : '';
                return (activeItem.label || activeItem.name) + postfix ;
            }

            return '';
        },
        isDisabled() {
            return 0 === this.empty.length && 0 === Object.keys(this.items || {}).length;
        }
    },
    methods: {
        searchById(id) {
            return (this.items[0] || {}).hasOwnProperty("id") ?
                // Find by collection object field.
                Object.values(this.items).find(item => String(item.id) === String(id)) :
                // Find by array index/property.
                this.items[id];
        },
    }
};
