<template>
    <div class="title-bar">
        <div class="title-bar__title">
            <slot name="default"></slot>
        </div>
        <div class="title-bar__actions">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "TitleBar"
}
</script>
