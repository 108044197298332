import {escObject} from "@common/utils/object";

export default {
    name: "confirmMixin",
    data() {
        return {
            isConfirm: false,
            confirmContext: {},
            confirmCallback: (thisComponent) => true,
            cancelCallback: (thisComponent) => true,
        };
    },
    methods: {
        /**
         * @param {{title: String, body: String|String[], confirmText: String, cancelText: String}} context
         * @param {function} confirm
         * @param {function} cancel
         */
        showConfirm(context = {}, confirm = () => true, cancel = () => true) {
            context = escObject(context);

            this.confirmContext = {
                title: context.title || 'Подтверждение действия',
                body: (Array.isArray(context.body) ? context.body.join("<br>\n") : context.body) || '',
                confirmText: context.confirmText || undefined,
                cancelText: context.cancelText || undefined,
            };

            if (confirm) this.confirmCallback = confirm;
            if (cancel) this.cancelCallback = cancel;
        },
        onConfirmClose() {
            if (!this.isConfirm) {
                if (false !== this.cancelCallback(this)) {
                    this.confirmContext = {};
                }
            }

            this.isConfirm = false;
        },
        onConfirmApply() {
            // Skip on confirm close.
            this.isConfirm = true;
            if (false !== this.confirmCallback(this)) {
                this.confirmContext = {};
            }
        },
    },
};
