var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dropdown",
    {
      staticClass: "component--select",
      attrs: {
        id: _vm.name,
        disabled: _vm.isDisabled,
        unfocusByHandler: _vm.unfocusByHandler,
      },
      on: { "dropdown-focus": (v) => _vm.$emit("dropdown-focus", v) },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function () {
              return [
                _vm._t("title", function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "placeholder placeholder_inline placeholder_icon",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm._activeLabel || _vm.placeholder) +
                            "\n                "
                        ),
                        !_vm.isDisabled
                          ? _c("Icon", { attrs: { id: "arrow-down" } })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [_vm._t("actions")]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      0 === Object.keys(_vm.items || {}).length
        ? _c(
            "div",
            { staticClass: "empty" },
            [
              _vm._t("empty", function () {
                return [_vm._v(_vm._s(_vm.empty))]
              }),
            ],
            2
          )
        : _c(
            "Scroll",
            { staticStyle: { "max-height": "220px" } },
            [
              !_vm.multiple
                ? _c("RadioList", {
                    attrs: {
                      name: _vm.name,
                      items: _vm.items,
                      active: String(_vm.active),
                      labelCallback: _vm.labelCallback,
                    },
                    on: {
                      radioListChange: (value) =>
                        _vm.$emit("selectChange", value),
                      select: (value) => _vm.$emit("select", value),
                      radioListSelect: _vm.radioListSelect,
                    },
                  })
                : _c("CheckboxList", {
                    attrs: {
                      name: _vm.name,
                      items: _vm.items,
                      active: String(_vm.active),
                      labelCallback: _vm.labelCallback,
                    },
                    on: {
                      change: (values) => _vm.$emit("selectChange", values),
                    },
                  }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }