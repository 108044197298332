import {escNumId} from "@common/utils/text";

export default {
    state: {
        /** @var {String} Current project's cabinet id. */
        cabinetId: '',

        /** @var {String} Current project id */
        projectId: '',

        /** @var {String} Current project name */
        projectName: '',

        resultId: '',
        runId: '',
        urlId: '',

        /** @var {Object} Current authorized user */
        authUser: {},
        canEdit: false,
        isTaskAccess: false,
        // Id to label
        runs: {},

        userProjects: [],
        userProjectsLoading: false,
    },
    getters: {
        authUserName(state) {
            return [state.authUser.lastname, state.authUser.firstname]
                .filter(name => !!name)
                .join(' ');
        },
    },
    mutations: {
        setTaskCreateAccess(state, access) {
            state.isTaskAccess = access;
        },
        setAuthUser(state, user) {
            state.authUser = {
                id: String(user.id),
                firstname: user.firstname || '',
                lastname: user.lastname || '',
                isSemantex: user.isSemantex || false,
            };
        },
        setCabinetId(state, cabinetId) {
            state.cabinetId = escNumId(cabinetId);
        },
        setProjectId(state, projectId) {
            state.projectId = escNumId(projectId);
        },
        setProjectName(state, projectName) {
            state.projectName = projectName;
        },
        setResultId(state, resultId) {
            state.resultId = escNumId(resultId);
        },
        setRunId(state, runId) {
            state.runId = escNumId(runId);
        },
        setUrlId(state, urlId) {
            state.urlId = escNumId(urlId);
        },
        setCanEdit(state, payload) {
            state.canEdit = !!payload;
        },
        setRuns(state, runs) {
            state.runs = runs;
        },
        setUserProjects(state, projects) {
            state.userProjects = [...projects];
        },
    },
    actions: {
        async fetchUserProjectsList({rootState, state, commit}) {
            const api = '/plan-tasks/api/get-projects/';
            return await axios.get(api)
                .then((response) => {
                    const projects = response.data.projects || [];
                    if (projects.length) {
                        const projectsList = projects.map((pr) => ({...pr, name: `#${pr.id} ${pr.name}`}));
                        commit('setUserProjects', projectsList);
                    }

                    return response.data.projects;
                })
                .catch(() => {
                    state.userProjectsLoading = false;
                    commit('setUserProjects', []);
                    return [];
                })
                .finally(() => state.userProjectsLoading = false);
        },
    },
};
