var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tip",
          rawName: "v-tip",
          value: { text: _vm.computedText, width: _vm.width },
          expression: "{text: computedText, width}",
        },
      ],
      staticClass: "tip-message",
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }