import {tooltipDestroy, tooltipCreate} from "@common/utils/tooltip";

function create(el, binding) {
    el.setAttribute('title', binding.value?.text || el.innerText);
    tooltipCreate(el, binding.value?.width || 'auto');
}

function destroy(el, binding) {
    tooltipDestroy(el);
}

export default {
    inserted(el, binding) {
        create(el, binding);
    },
    unbind(el, binding) {
        destroy(el, binding);
    },
    update(el, binding) {
        destroy(el, binding);
        create(el, binding);
    }
};
