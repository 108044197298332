<template>
    <label class="radio radio_custom">
        <input
            type="radio"
            class="radio__input"
            :name="inputName"
            :value="inputValue"
            :checked="inputChecked"
            v-on:input="$emit('inputChange', $event); $emit('select', item);"
            v-on:click="$emit('inputSelect', $event)"
        />

        <template v-if="labelPostfix">
            <div class="radio__label" v-overflow-tip>{{ label }}<br>
                <small class="radio__label-postfix">({{ labelPostfix }})</small>
            </div>
        </template>
        <template v-else>
            <span class="radio__label" v-overflow-tip>
              <slot name="label">{{ label }}</slot>
            </span>
        </template>
    </label>
</template>

<script>
export default {
    name: "Radio",
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        inputName: String,
        inputValue: String,
        inputChecked: Boolean,
        label: String,
        labelPostfix: String,
    }
}
</script>
