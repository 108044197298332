<template>
    <div class="scroll">
        <div class="simplebar-wrapper">
            <div class="simplebar-height-auto-observer-wrapper">
                <div class="simplebar-height-auto-observer"/>
            </div>
            <div class="simplebar-mask">
                <div class="simplebar-offset">
                    <div
                        class="simplebar-content-wrapper"
                        ref="scrollElement"
                        v-on="{
                            ...($listeners.scroll && {
                                scroll: $listeners.scroll,
                            })
                        }"
                    >
                        <div class="simplebar-content" ref="contentElement">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
            <div class="simplebar-placeholder"/>
        </div>
        <div class="simplebar-track simplebar-horizontal">
            <div class="simplebar-scrollbar"/>
        </div>
        <div class="simplebar-track simplebar-vertical">
            <div class="simplebar-scrollbar"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "Scroll",
    data() {
        return {
            instance: null,
        }
    },
    computed: {
        scrollElement() {
            return this.$refs.scrollElement;
        },
        contentElement() {
            return this.$refs.contentElement;
        }
    },
    mounted() {
        this.instance = new SimpleBar(this.$el, {
            autoHide: false,
            scrollbarMaxSize: 24,
        });
    }
}
</script>