<template>
    <div class="tip-message" v-tip="{text: computedText, width}">
        <slot></slot>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "TipMessage",
    props: {
        messageId: String,
        text: String,
        width: {
            type: String,
            default: '300px'
        },
    },
    computed: {
        ...mapState({
            messages: state => state.lang.messages,
        }),
        computedText() {
            return this.messages[this.messageId] || this.text;
        },
    },
}
</script>

<style lang="scss">
.tip-message {
    display: inline-block;
}
</style>