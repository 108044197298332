import {escObject} from "@common/utils/object";

const routePath = '{$routePath}';
const apiMethod = '{$apiMethod}';
const cabinetId = '{$cabinetId}';
const projectId = '{$projectId}';
const resultId = '{$resultId}';

const routes = {
    // @formatter:off
    'ecom':               `/serm/project/${projectId}/api/auto/api/ecom/${routePath}`,
    'support':            `/support/#${routePath}`,
    'serm':               `/serm/project/${projectId}/${routePath}`,
    'cabinet':            `/serm/project/${projectId}/api/auto/c-${cabinetId}/${routePath}`,
    'report':             `/serm-reports/project/${projectId}/report/#${routePath}`, // routePath: tabSlug
    'report-public':      `/public/project/${projectId}/report/#${routePath}`,
    'report-api':         `/serm/project/${projectId}/api/auto/c-${cabinetId}/${projectId}/api-report/${routePath}`, // routePath: path
    'plan':               `/plan-tasks/cabinet-api/${cabinetId}/auto/c-${cabinetId}/plan/${routePath}`,
    'plan-api':           `/plan-tasks/api/${cabinetId}/${routePath}/`,
    'plan-tasks-api':     `/plan-tasks/api/${cabinetId}/${projectId}/${routePath}/`,
    'plan-tasks-project': `/plan-tasks/project/${projectId}/api/${routePath}`,
    'plan-url':           `/serm/project/${projectId}/api/auto/c-${cabinetId}/${resultId}/plan-url/${routePath}`,
    'task-url':           `/serm/project/${projectId}/api/auto/${projectId}/plan-url/${routePath}`,
    'task-url-convert':   `/serm/project/${projectId}/api/run-convert/${projectId}/plan-url/${routePath}`,
    'task-url-api':       `/serm/project/${projectId}/api/${routePath}/`,
    'project':            `/serm/project/${projectId}/api/auto/c-${cabinetId}/${projectId}/${routePath}`,
    'analysis-url':       `/serm-reports/project/${projectId}/analysis/url/${routePath}/`,
    'result/form':        `/serm/project/${projectId}/api/auto/c-${cabinetId}/${projectId}/url/${routePath}`, // routePath: resultId
    'project-plan':       `/serm/project/${projectId}/api/auto/c-${cabinetId}/${projectId}/plan/${routePath}`,
    'api-search-results': `/serm/project/${projectId}/api/auto/c-${cabinetId}/${projectId}/api-search-results/${routePath}`,
    'new-project':        `/serm-reports/project/${projectId}`,
    'new-public-project': `/public/project/${projectId}`,
    'api-url-tags':       `/serm/project/${projectId}/api/auto/c-${cabinetId}/${projectId}/api-url-tags/${routePath}`,
    'api':                `/serm/project/${projectId}/api/${apiMethod}`,
    'api-result':         `/serm/project/${projectId}/api/${apiMethod}/api/result`,
    'api-init':           `/c-${cabinetId}/${projectId}/${routePath}`
    // @formatter:on
};

export function getRoutePlan(action) {
    return getRoute('plan', action);
}

export function getRoutePlanUrl(action) {
    return getRoute('plan-url', action);
}

export function getRouteTaskUrl(action) {
    return getRoute('task-url', action);
}

export function getRoute(controller, action = '', method = '') {
    let path = routes[controller];
    if (!path) throw new Error(`Controller ${controller} not found`);

    if (path.includes(cabinetId)) {
        path = path.replaceAll(cabinetId, getRouteParam(store.state.common, 'cabinetId'));
    }

    if (path.includes(projectId)) {
        path = path.replaceAll(projectId, getRouteParam(store.state.common, 'projectId'));
    }

    if (path.includes(resultId)) {
        path = path.replace(resultId, getRouteParam(store.state.planUrl, 'runUrlId'));
    }

    return path.replace(routePath, action).replace(apiMethod, method);
}

function getRouteParam(state, param) {
    if (!escObject(state)[param]) throw new Error(`Param "${param}" is required for define route.`);
    return state[param];
}
