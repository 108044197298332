export default {
    name: "FocusMixin",
    data() {
        return {
            focus: false,
        };
    },
    watch: {
        focus() {
            if (this.focus) {
                Vue.nextTick(() => {
                    this.$el.unfocusEvent = (event) => {
                        // When click on target.
                        // When click on contain target.
                        // When target was deleted from document. (click on delete element in component)
                        if (!(this.$el === event.target || this.$el.contains(event.target) || !document.contains(event.target))) {
                            this.focus = false;
                            this.$emit('unfocus', event);
                        }
                    };

                    this.$emit('watch-focus-start');
                    document.body.addEventListener('click', this.$el.unfocusEvent);
                });
            } else {
                document.body.removeEventListener('click', this.$el.unfocusEvent);
                this.$emit('watch-focus-end');
            }
        }
    }
};
