var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        class: _vm.className,
        attrs: {
          id: _vm.id,
          role: "dialog",
          tabindex: "",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          {
            class: "modal-dialog" + (_vm.fullMode ? " modal-xl" : ""),
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              !_vm.fullMode && _vm.title
                ? _c("div", { staticClass: "modal-header" }, [
                    _c("h5", { staticClass: "w-100 text-center modal-title" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm.fullMode
                  ? _c(
                      "div",
                      { staticClass: "sticky-top modal-sticky-top" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-10" }, [
                            _vm.title
                              ? _c("div", { staticClass: "head-bl" }, [
                                  _vm._v(_vm._s(_vm.title)),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._t("header"),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-center" },
                  [_vm.open ? _vm._t("default") : _vm._e()],
                  2
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" },
        },
        [
          _c("i", { staticClass: "fas fa-chevron-left" }),
          _vm._v("\n                                    Назад"),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }