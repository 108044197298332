<template>
    <div class="reactive-alert fade modal" role="dialog" tabindex="" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-body__title" v-if="title">{{ title }}</div>
                    <div class="modal-body__message">
                        <slot name="default"></slot>
                    </div>
                    <div class="modal-body__actions">
                        <slot name="actions"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import modalMixin from "@/vue/share/modalMixin";

export default {
    mixins: [modalMixin],
    props: {
        timeout: {
            type: Number,
            default: 3,
        }
    },
    mounted() {
        const $el = this.openModal();

        if (this.timeout > 0) {
            setTimeout(() => $el.modal('hide'), this.timeout * 1000);
        }
    },
    beforeDestroy() {
        this.closeModal();
    }
}
</script>

<style lang="scss">
@import "/resources/scss/modules/modal/reactive-alert";
</style>
