var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown", class: { "dropdown--open": _vm.focus } },
    [
      _c(
        "div",
        {
          staticClass: "dropdown__handler",
          on: {
            click: function ($event) {
              _vm.focus = _vm.unfocusByHandler ? !_vm.focus : true
              _vm.onClick($event)
            },
          },
        },
        [_vm._t("title")],
        2
      ),
      _vm._v(" "),
      (_vm.focus || _vm.forceFocus) && !_vm.disabled
        ? _c(
            "div",
            { staticClass: "dropdown__body" },
            [
              _vm._t("default"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dropdown__actions" },
                [_vm._t("actions")],
                2
              ),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }