var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      class: {
        checkbox: true,
        checkbox_disabled: _vm.disabled,
      },
    },
    [
      _c("input", {
        staticClass: "checkbox__input",
        attrs: { type: "checkbox", name: _vm.name, disabled: _vm.disabled },
        domProps: { value: _vm.value, checked: _vm.checked },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          change: function ($event) {
            return _vm.$emit("change", $event)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [{ name: "overflow-tip", rawName: "v-overflow-tip" }],
          staticClass: "checkbox__label",
        },
        [
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm.label))]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }