var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dropdown",
    {
      attrs: { id: _vm.id, disabled: _vm.disabled, unfocusByHandler: false },
      on: { "dropdown-focus": _vm.changeFocus },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function () {
              return [
                !_vm.isShowInput
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "placeholder placeholder_inline placeholder_icon",
                        on: {
                          click: function ($event) {
                            _vm.isShowInput = !_vm.isShowInput
                          },
                        },
                      },
                      [
                        !_vm.disabled
                          ? _c("Icon", { attrs: { id: "arrow-down" } })
                          : _vm._e(),
                        _vm._v(
                          "\n\n            " +
                            _vm._s(_vm.placeholder) +
                            "\n        "
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.inputBlur,
                            expression: "inputBlur",
                          },
                        ],
                        staticClass:
                          "placeholder placeholder_input placeholder_icon",
                        staticStyle: { "--icons-offset": "50px" },
                      },
                      [
                        _c("input", {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          attrs: { type: "text" },
                          domProps: { value: _vm.searchText },
                          on: {
                            input: function ($event) {
                              return _vm.$emit(
                                "searchInput",
                                $event,
                                $event.target.value
                              )
                            },
                            paste: function ($event) {
                              _vm.$emit(
                                "searchPaste",
                                $event,
                                (
                                  $event.clipboardData ||
                                  _vm.window.clipboardData
                                ).getData("Text")
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.searchText
                          ? _c("Icon", {
                              staticStyle: { "--size": "16px" },
                              attrs: { id: "delete", color: "purple" },
                              on: { click: _vm.resetSearchText },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("Icon", { attrs: { id: "lens" } }),
                      ],
                      1
                    ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [_vm._t("actions")]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [_vm._v(" "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }