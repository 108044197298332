var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "checkbox-list" },
    _vm._l(_vm.itemsArray, function (item) {
      return _c(
        "li",
        { key: item.id, staticClass: "checkbox-list__item" },
        [
          _c("Checkbox", {
            class: item.className,
            attrs: {
              value: String(item.id),
              checked: _vm.stateById[item.id].checked,
              disabled: !!item.disabled,
              label: _vm.labelCallback(item),
              name: item.itemName || "",
            },
            on: { input: _vm.setResult },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }