export default {
    state: {
        /** Translate messages from php to js */
        messages: {},
    },

    mutations: {
        setMessages(state, payload) {
            state.messages = payload;
        },
    }
};