<template>
      <div class="modal fade" :class="{'modal--scroll': modalSize === 'custom'}" tabindex="-1" role="dialog" aria-hidden="true">
        <div :class="modalSize ? `modal-${modalSize}` : ''" class="modal-dialog" role="document">
            <div class="modal-content" style="overflow: auto">
                <div class="modal-header">

                    <h5 v-if="title" class="modal-title">{{ title }}</h5>


                    <slot name="left-actions">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="actions"></slot>

                    <div class="modal-body__default">
                        <slot name="default"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import modalMixin from "@/vue/share/modalMixin";

export default {
    mixins: [modalMixin],
    props: {
      title: {
        type: String,
        default: '',
      },
      modalSize: {
        type: String,
        default: 'xl',
        required: false,
        validator: prop => ['xl', 'sm', 'lg', 'custom', ''].includes(prop)
      }
    },
    mounted() {
        this.openModal();
        this.$on('reactiveModalClose', () => this.closeModal());
    },
    beforeDestroy() {
        this.closeModal();
    }
}
</script>

<style lang="scss" scoped>
.modal-dialog.modal-custom {
  min-width: unset;
  max-width: unset;
}

.modal-body,
.modal-body__default {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-body {
  overflow: initial
}

.modal.modal--scroll.show,
.modal.show {
  overflow: auto;
}
</style>
