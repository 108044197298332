export default  {
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    methods: {
        openModal() {
            return jQuery(this.$el)
                // No layout relative rules.
                .appendTo('body')
                // Emit "close" event in component when bootstrap modal to closed.
                .on('hidden.bs.modal', () => {
                    this.$emit('close');
                })
                // Show modal on mounted.
                .modal('show');
        },
        closeModal() {
            jQuery(this.$el)
                .modal('hide');
        }
    }
};
