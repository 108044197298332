var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "reactive-confirm fade modal",
      attrs: { role: "dialog", tabindex: "", "aria-hidden": "true" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-body" }, [
            _vm.title
              ? _c("div", {
                  staticClass: "modal-body__title",
                  domProps: { innerHTML: _vm._s(_vm.title) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body__message" },
              [_vm._t("default")],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body__actions" },
              [
                _vm._t("actions", function () {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary w-160",
                        attrs: {
                          disabled: !!_vm.confirmDisabled,
                          title:
                            typeof _vm.confirmDisabled !== "boolean"
                              ? _vm.confirmDisabled
                              : false,
                          "data-dismiss": "modal",
                        },
                        on: {
                          click: () =>
                            !_vm.confirmDisabled && _vm.$emit("confirm"),
                        },
                      },
                      [_vm._v(_vm._s(_vm.confirmText))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary w-160 ml-2",
                        attrs: { "data-dismiss": "modal" },
                      },
                      [_vm._v(_vm._s(_vm.cancelText))]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }