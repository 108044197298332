export function tooltipCreate(element, width = '300px') {
    element.tooltip = new bootstrap.Tooltip(element, {
        boundary: document.body,
        container: 'body',
        placement: 'top',
        template:
            '<div class="tooltip" role="tooltip">' +
            '   <div class="arrow"></div>' +
            '   <div class="tooltip-inner" style="width: ' + width + '"></div>' +
            '</div>',
    });
}

export function tooltipDestroy(element) {
    return element.tooltip && element.tooltip.dispose();
}

export function tooltipShow(element) {
    return element.tooltip && element.tooltip.show();
}

export function tooltipHide(element) {
    return element.tooltip && element.tooltip.hide();
}
