import {saveObjectToArray} from "@common/vue/components/form/utils";

/**
 * @requires data.validateList
 * @property {Array} validateList
 */
export default {
    name: "listMixin",
    props: {
        items: {
            type: [Array, Object],
            require: true,
        },
        active: {
            type: [Array, String],
            default: () => [],
        },
    },
    computed: {
        itemsArray() {
            return saveObjectToArray(this.items);
        },
        activeArray() {
            return Array.isArray(this.active) ?
                this.active.map(a => String(a)) : [this.active];
        },
        stateById() {
            const stateById = {};

            this.itemsArray.forEach((item) => {
                const itemId = String(item.id);
                const checked = this.activeArray.includes(itemId);

                stateById[item.id] = {
                    checked,
                };
            });

            return stateById;
        },
    },
    methods: {
        onInput(event, newResult, prevResult) {
            if (Array.isArray(this.validateList)) {
                // When all is true.
                return this.validateList.reduce(
                    (reduced, cb) => reduced && cb(event, newResult, prevResult), true);
            }

            return true;
        },
    }
};
