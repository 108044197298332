<template>
    <Dropdown
        :id="id"
        :disabled="disabled"
        :unfocusByHandler="false"
        v-on:dropdown-focus="changeFocus">

        <template v-slot:title>
            <div
                v-if="!isShowInput"
                @click="isShowInput = !isShowInput"
                class="placeholder placeholder_inline placeholder_icon">
                <Icon id="arrow-down" v-if="!disabled"/>

                {{ placeholder }}
            </div>
            <div
                v-else
                class="placeholder placeholder_input placeholder_icon"
                v-click-outside="inputBlur"
                style="--icons-offset: 50px;"
            >
                <input
                    type="text"
                    v-focus
                    :value="searchText"
                    @input="$emit('searchInput', $event, $event.target.value)"
                    @paste="$emit('searchPaste', $event, ($event.clipboardData || window.clipboardData).getData('Text'))"
                >
                <Icon
                    v-if="searchText"
                    @click="resetSearchText"
                    id="delete"
                    color="purple"
                    style="--size: 16px;"
                />
                <Icon id="lens"/>
            </div>
        </template>

        <slot></slot>
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>
    </Dropdown>
</template>

<script>
export default {
    name: "DropdownSearch",
    props: {
        id: {
            type: String,
            default: '',
        },
        searchText: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isShowInput: false,
        }
    },
    methods: {
        /**
         * Click outside event callback.
         */
        inputBlur(e) {
            if (!this.searchText.trim().length) {
                this.isShowInput = false;
            }
        },

        /**
         * Click on reset button event callback.
         */
        resetSearchText(e) {
            this.$emit('searchReset', e);
            this.hideDropdowns();
        },

        /**
         * Dropdown (Child component) change focus event.
         */
        changeFocus(focus) {
            this.$emit('dropdown-focus', focus);
        },

        hideDropdowns() {
            // Click outside dispatch for blur.
            document.body.dispatchEvent(new Event('click'));
        },
    }
}
</script>
