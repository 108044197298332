import {getRoute} from "@common/vue/store/routes";
import {escObject, isObject, objectMap} from "@common/utils/object";

export default {
    state: {
        // All project tags.
        all: {},
        byUrl: {},
    },

    getters: {
        getAllUrlTags(state) {
            return state.all;
        }
    },

    mutations: {
        setUrlTag(state, {id, tag}) {
            Vue.set(state.all, id, {
                name: tag.name,
                color: tag.color,
            });
        },
        deleteUrlTag(state, tagId) {
            Vue.delete(state.all, tagId);
        },
        setTagsToUrl(state, {urlId, tags}) {
            Vue.set(state.byUrl, urlId, (tags || []).map(tag => String(tag.id || tag)));
        },
        addTagsToUrl(state, {urlId, tagId}) {
            urlId = (Array.isArray(urlId) ? urlId : [urlId]);
            tagId = String(tagId);

            urlId.forEach(eachUrlId => {
                if (!state.byUrl[eachUrlId]) state.byUrl[eachUrlId] = [];
                if (!state.byUrl[eachUrlId].includes(tagId)) {
                    state.byUrl = {
                        ...state.byUrl,
                        [eachUrlId]: [...state.byUrl[eachUrlId], tagId]
                    };
                }
            });
        },
        removeTagsToUrl(state, {urlId, tagId}) {
            urlId = (Array.isArray(urlId) ? urlId : [urlId]);
            tagId = String(tagId);

            urlId.forEach(eachUrlId => {
                if (!state.byUrl[eachUrlId]) state.byUrl[eachUrlId] = [];
                Vue.set(state.byUrl, eachUrlId, state.byUrl[eachUrlId].filter(tid => tid !== tagId));
            });
        },
        removeTagsToUrlByTag(state, {tagId}) {
            Vue.set(state.byUrl, objectMap(state.byUrl, ([urlId, tags]) => {
                return [urlId, (tags || []).filter(tid => tid !== tagId)];
            }));
        },
    },

    actions: {
        async fetchTags({rootState, state}) {
            return axios.get(getRoute('api-url-tags', 'get-tags'))
                .then(response => {
                    // Create new tag list object.
                    state.all = escObject(response.data.tags);
                    return response;
                });
        },

        /**
         * Create tag in result.
         */
        async setupTag({rootState, state, commit}, {urlId, tag}) {
            tag = {
                color: tag.color,
                name: tag.name,
            }

            return axios.post(getRoute('api-url-tags', 'setup-tag'), {urlId, tag})
                .then(response => {
                    if ('success' !== response.data?.status) throw new Error(response.data?.errors);
                    if (!response.data.tag || !response.data.tag[0]) throw new Error();

                    const tagId = response.data.tag[0].id;

                    // Add tag to list.
                    commit('setUrlTag', {id: tagId, tag});

                    // Set tag to url.
                    commit('addTagsToUrl', {urlId, tagId});

                    return response;
                })
        },
        async createTag({rootState, state, commit}, {tagName, tagColor}) {
            const tag = {color: tagColor, name: tagName};

            return axios.post(getRoute('api-url-tags', 'create-tag'), {tag})
                .then(response => {
                    if ('success' !== response.data?.status) throw response.data?.errors;

                    const tagId = response.data.result?.id;
                    if (!tagId) throw new Error();

                    // Add tag to list.
                    commit('setUrlTag', {id: tagId, tag});
                    return response;
                })
        },
        async updateTag({rootState, state, commit}, {id, tagName, tagColor}) {
            const tag = {name: tagName, color: tagColor};

            return axios.post(
                getRoute('api-url-tags', 'update-tag'),
                tag,
                {params: {id}}
            )
                .then(response => {
                    if ('success' !== response.data?.status) throw response.data?.errors;

                    commit('setUrlTag', {id, tag});
                });
        },
        async deleteTag({rootState, state, commit}, {tagId}) {
            return axios.delete(getRoute('api-url-tags', 'delete-tag'), {params: {id: tagId}})
                .then(response => {
                    if ('success' !== response.data?.status) throw new Error();

                    commit("deleteUrlTag", tagId);
                    commit("removeTagsToUrlByTag", tagId);

                    return response;
                });
        },
        async fetchUrlTags({rootState, state, commit}, {urlId}) {
            return axios.post(getRoute('api-url-tags', 'get-url-tags'), {urlId})
                .then(response => {
                    if (response.data.tagsToUrl) {
                        Object.entries(response.data.tagsToUrl).forEach(([urlId, tags]) => {
                            commit('setTagsToUrl', {urlId, tags});
                        });
                    }

                    return response;
                });
        },
        async updateUrlTag({commit, rootState, state}, {urlId, tagsId}) {
            return axios
                .post(
                    getRoute('api-url-tags', 'update-url-tag'),
                    {taskUrlTagIds: tagsId},
                    {params: {urlId}}
                )
                .then(response => {
                    if ("success" !== response.data.status) throw new Error();

                    if (!response.data.needToConfirm) {
                        // Set new relatives now.
                        commit('setTagsToUrl', {urlId, tags: tagsId});
                    }

                    // Update already fetched runs. (runs have a urls with tags.)
                    if (isObject(state.runs)) {
                        state.runs = {...state.runs};
                    }

                    return response;
                });
        },
    },
};
