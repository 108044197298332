<template>
    <div class="radio-list">
        <ul class="radio-list__list">
            <li class="radio-list__item" v-for="item in itemsArray" :key="item.id">
                <Radio
                    :class="item.className"
                    :inputName="name"
                    :inputValue="String(item.id)"
                    :inputChecked="stateById[item.id].checked"
                    :label="labelCallback(item)"
                    :label-postfix="item.labelPostfix"
                    :item="item"
                    v-on:inputChange="e => $emit('radioListChange', e.target.value)"
                    v-on:select="(item) => $emit('select', item)"
                    v-on:inputSelect="(e) => ($emit('radioListSelect', e.target))"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import Radio from "@common/vue/components/form/Radio";
import listMixin from "@common/vue/components/form/listMixin";

/**
 * Example:
 * <RadioList
     :items="[{id: 1, label: 'text'}]"
     :active="1"
     @radioListChange="() => {}"
     @radioListSelect="() => {}"
 />
 */
export default {
    name: "RadioList",
    components: {Radio},
    mixins: [listMixin],
    props: {
        active: String,
        name: {type: String, required: true},
        labelCallback: {
            default: () => {
                return item => item.label;
            }
        }
    },
}
</script>
