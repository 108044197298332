<template>
    <label :class="{
        'checkbox': true,
        'checkbox_disabled': disabled
    }">
        <input
            type="checkbox"
            class="checkbox__input"
            @input="$emit('input', $event)"
            @change="$emit('change', $event)"
            :name="name"
            :value="value"
            :checked="checked"
            :disabled="disabled"/>

        <span class="checkbox__label" v-overflow-tip>
            <slot>{{ label }}</slot>
        </span>
    </label>
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        value: {
            type: String,
            default: '',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        name: {
          type: String,
          default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
}
</script>
