<template>
    <ul class="checkbox-list">
        <li class="checkbox-list__item" v-for="item in itemsArray" :key="item.id">
            <Checkbox
                @input="setResult"
                :class="item.className"
                :value="String(item.id)"
                :checked="stateById[item.id].checked"
                :disabled="!!item.disabled"
                :label="labelCallback(item)"
                :name="item.itemName || ''"
            />
        </li>
    </ul>
</template>

<script>
import Checkbox from "@common/vue/components/form/Checkbox";
import listMixin from "@common/vue/components/form/listMixin";

/**
 * Example:
 * <CheckboxList
     :items="[{id: 1, label: 'text', itemName: 'text'}]" itemName - определит атрибут name у чекбокса
     :active="[1]"
     :min="1"
     :max="12"
     @change="() => {}"
 />
 */
export default {
    name: "CheckboxList",
    components: {Checkbox},
    mixins: [listMixin],
    props: {
        sortByChecked: {
            type: Boolean,
            default: false,
        },
        labelCallback: {
            default: () => {
                return item => item.label;
            }
        }
    },
    methods: {
        setResult(event) {
            const previousResult = new Set(this.activeArray);
            const newResult = new Set(this.activeArray);

            if (event.target.checked) {
                newResult.add(event.target.value);
            } else {
                newResult.delete(event.target.value);
            }

            if (!this.onInput(event, newResult, previousResult)) {
                event.target.checked = !event.target.checked;
                return false;
            }

            this.$emit('change', Array.from(newResult));
        },
    }
}
</script>
