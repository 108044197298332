import {escArray} from "@common/utils/array";

function getResponseSegment(segment) {
    return {
        id: segment.id,
        name: segment.name,
        type: segment?.type,
        keywords: segment.keywords,
    };
}

export default {
    state: {
        segments: [],
        segmentsTypes: [],
    },

    mutations: {
        segments(state, segments) {
            if (Array.isArray(segments)) {
                state.segments = segments.map(getResponseSegment);
            } else {
                state.segments = [];
            }
        },
        segmentsTypes(state, segmentsTypes) {
            state.segmentsTypes = escArray(segmentsTypes);
        },
    },

    getters: {
    },

    actions: {
        async fetchSegments({commit}, {projectId}) {
            return axios
                .get(`/keyword/${projectId}`)
                .then(response => {
                    if (response.data?.segments) {
                        commit('segments', response.data.segments);
                    }

                    if (response.data?.segmentsTypes) {
                        commit('segmentsTypes', response.data.segmentsTypes);
                    }

                    return response;
                });
        },

        async updateSegments({commit, state, dispatch}, {projectId, segments}) {
            // Empty keywords filter.
            const data = {segments: segments.filter(s => (s?.keywords || '').trim().length > 0)};

            return axios
                .post(`/keyword/update/${projectId}`, data)
                .then(response => {
                    if ('success' === response.data?.status) {
                        commit('segments', response.data.segments);
                    }

                    return response;
                });
        },

        async deleteSegment({commit, state, dispatch}, {projectId, segmentId}) {
            return axios
                .post(`/keyword/delete/${projectId}`, {id: segmentId})
                .then(response => {
                    if ('success' === response.data?.status) {
                        commit('segments', state.segments
                            .filter(segment => String(segment.id) !== String(segmentId)));
                    }
                });
        },
    },
};
