<template>
    <div class="dialog">
        <ReactiveConfirm
            v-if="Object.keys(confirmContext).length"
            :title="confirmContext.title"
            :confirmText="confirmContext.confirmText"
            :cancelText="confirmContext.cancelText"
            @close="onConfirmClose"
            @confirm="onConfirmApply"
        >
            <div v-html="confirmContext.body"></div>
        </ReactiveConfirm>

        <ReactiveAlert
            v-if="Object.keys(alertContext).length"
            :class="`reactive-alert_${alertContext.className}`"
            :title="alertContext.title"
            :timeout="alertContext.timeout"
            @close="onAlertClose"
        >
            <p class="mb-1" v-for="message in alertContext.messages" v-html="message"></p>
        </ReactiveAlert>
    </div>
</template>

<script>
import confirmMixin from "@common/vue/components/Dialog/confirmMixin";
import alertMixin from "@common/vue/components/Dialog/alertMixin";

const UNHANDLED_ERROR = 'Произошла непредвиденная ошибка. Попробуйте позже или обратитесь в тех. поддержку.';

export default {
    name: "Dialog",
    mixins: [confirmMixin, alertMixin],
    methods: {
        onSaveError(errors = UNHANDLED_ERROR) {
            this.showErrors({title: 'Ошибка при сохранении'}, errors);
        },
        onDeleteError(errors = UNHANDLED_ERROR) {
            this.showErrors({title: 'Ошибка при удалении'}, errors);
        }
    },
};
</script>
