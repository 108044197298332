/**
 * Simple object check.
 *
 * @param {*|Object} payload
 * @returns {boolean}
 */
export function isObject(payload) {
    return (payload && typeof payload === 'object' && !Array.isArray(payload));
}

/**
 * @param {*|Object} payload
 * @return {Object}
 */
export function escObject(payload) {
    return isObject(payload) ? payload : {};
}

/**
 * Get any (first) value
 *
 * @param {*|Object} payload
 * @return {*|null}
 */
export function firstValue(payload) {
    payload = escObject(payload);
    return payload[Object.keys(payload)[0]] || null;
}

/**
 * Filter for assoc array (object) with save keys.
 *
 * @param payload {Object} Associative array
 * @param predicate {function} filter method, stay elements with true result
 */
export function objectFilter(payload, predicate = (([key, val]) => !isNaN(val) || (val && val.length))) {
    return Object.fromEntries(Object.entries(escObject(payload)).filter(predicate));
}

/**
 * @param {*|Object} payload
 * @param {function([key, value])} predicate
 * @return {Object} Same payload, but processed.
 */
export function objectMap(payload, predicate = (([a, b]) => [a, b])) {
    return Object.fromEntries(Object.entries(escObject(payload)).map(predicate));
}

/**
 * @param {*|Object} payload
 * @return {Object} Same payload object with string ID property.
 */
export function toStringID(payload) {
    payload = escObject(payload);
    return {...payload, id: payload.id ? String(payload.id) : null};
}

/**
 *
 * @param {Object} parent
 * @param {String} property
 * @param {*[]} result carry object
 * @return {*[]}
 */
export function collectChildren(parent, property = 'children', result = []) {
    if (Array.isArray(parent[property])) {
        result.push(...parent[property]);
        parent[property].forEach(children => collectChildren(children, property, result));
    }

    return result;
}
