export default {
    inserted(el, binding, vnode) {
        el.focus();

        el.callOnBlur = (e) => {
            const attrValue = vnode.context[binding.expression];
            if (!attrValue) return;

            if (attrValue instanceof Function) {
                attrValue(e);
            } else {
                console.error('Not callable v-focus expression');
            }
        };

        el.addEventListener('blur', el.callOnBlur);
    },
    unbind(el) {
        el.removeEventListener('blur', el.callOnBlur);
    }
};
