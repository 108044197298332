var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      Object.keys(_vm.confirmContext).length
        ? _c(
            "ReactiveConfirm",
            {
              attrs: {
                title: _vm.confirmContext.title,
                confirmText: _vm.confirmContext.confirmText,
                cancelText: _vm.confirmContext.cancelText,
              },
              on: { close: _vm.onConfirmClose, confirm: _vm.onConfirmApply },
            },
            [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.confirmContext.body) },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.alertContext).length
        ? _c(
            "ReactiveAlert",
            {
              class: `reactive-alert_${_vm.alertContext.className}`,
              attrs: {
                title: _vm.alertContext.title,
                timeout: _vm.alertContext.timeout,
              },
              on: { close: _vm.onAlertClose },
            },
            _vm._l(_vm.alertContext.messages, function (message) {
              return _c("p", {
                staticClass: "mb-1",
                domProps: { innerHTML: _vm._s(message) },
              })
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }