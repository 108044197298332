import {tooltipDestroy, tooltipCreate, tooltipShow} from "@common/utils/tooltip";
import { tooltipHide } from "../../utils/tooltip";

export default {
    inserted(el, binding, vnode) {
        el.tooltipInited = false;

        el.tooltipOnFocus = () => {
            if (!el.tooltipInited && el.scrollWidth > el.offsetWidth) {
                el.setAttribute('title', binding.value?.text || el.innerText);
                tooltipCreate(el, binding.value?.width || 'auto');
                tooltipShow(el);
                el.tooltipInited = true;
            }
        };

        el.tooltipOnLeave = () => tooltipHide(el);

        el.addEventListener('mouseenter', el.tooltipOnFocus);
        el.addEventListener('mouseleave', el.tooltipOnLeave);
    },
    unbind (el) {
        el.removeEventListener('mouseleave', el.tooltipOnFocus);
        el.removeEventListener('mouseleave', el.tooltipOnLeave);
        tooltipDestroy(el);
    },
};
