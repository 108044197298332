import segments from "@/vue/project/store/project/segments";
import topics from "@/vue/project/store/project/topics";
import branch from "@/vue/project/store/project/branch";
import regions from "@/vue/project/store/project/regions";
import {getRoute} from "@common/vue/store/routes";

function getResponseUser(user) {
    let firstname = user.firstname || '';
    let lastname = user.lastname || '';

    return {
        id: user.id || null,
        firstname,
        lastname,
        name: lastname + ' ' + firstname,
        isSemantex: user.isSemantex || null,
    };
}

export default {
    state: {
        /** Project runs */
        runDates: {},

        /** Users by project */
        users: {},

        // canCabinet($cabinetId, CABINET_ROLES) || canProject($projectId, PROJECT_WORK_ROLES);
        canEdit: false,
        isAccessAllow: false,
        isSemantex: false,

        /** Authorized user can get support service. */
        canSupport: false,

        status: '',

        budget: 0,
        budgetLoaded: false,

        ...segments.state,
        ...topics.state,
        ...branch.state,
        ...regions.state,
    },

    getters: {
        ...segments.getters,
        ...topics.getters,
        ...branch.getters,
        ...regions.getters,
    },

    mutations: {
        setAccessAllow(state, payload) {
            state.isAccessAllow = !!payload;
        },
        setIsSemantex(state, payload) {
            state.isSemantex = !!payload;
        },
        setCanEdit(state, payload) {
            state.canEdit = !!payload;
        },
        setCanSupport(state, payload) {
            state.canSupport = !!payload;
        },
        setRunDates(state, payload) {
            if (!Object.values(payload)[0]?.date) {
                const runDates = {};
                for (const [yearLabel, year] of Object.entries(payload || {})) {
                    for (const [monthLabel, month] of Object.entries(year)) {
                        for (const [runId, dateLabel] of Object.entries(month)) {
                            runDates[runId] = {
                                date: dateLabel,
                                year: yearLabel,
                                month: monthLabel,
                            };
                        }
                    }
                }

                state.runDates = runDates;
            } else {
                state.runDates = payload;
            }
        },
        setProjectUsers(state, users) {
            state.users = Object.fromEntries(
                Object.values(users)
                    .map(user => [user.id, getResponseUser(user)])
            );
        },
        addProjectUser(state, user) {
            state.users = {
                ...state.users,
                [user.id]: getResponseUser(user),
            };
        },
        setProjectBudget(state, budget) {
            state.budget = budget ? parseInt(budget) : '';
            state.budgetLoaded = true;
        },
        setProjectStatus(state, status) {
            state.status = status;
        },

        ...segments.mutations,
        ...topics.mutations,
        ...branch.mutations,
        ...regions.mutations,
    },

    actions: {
        async fetchBudget({state, commit}) {
            state.budgetLoaded = false;
            return axios.get(getRoute('project', 'get-budget'))
                .then(response => {
                    if (!response.data.result) {
                        throw response.data;
                    }

                    const oldStatus = state.status;
                    const oldBudget = state.budget;

                    commit('setProjectBudget', response.data.result.price);
                    commit('setProjectStatus', response.data.result.status);

                    return {
                        ...response.data.result,
                        status: response.data.result.status,
                        oldStatus,
                        budget: state.budget,
                        oldBudget,
                    };
                })
                .catch(() => {
                    commit('setProjectBudget', null);
                });
        },

        ...segments.actions,
        ...topics.actions,
        ...branch.actions,
        ...regions.actions,
    }
};
