var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      class: { "modal--scroll": _vm.modalSize === "custom" },
      attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog",
          class: _vm.modalSize ? `modal-${_vm.modalSize}` : "",
          attrs: { role: "document" },
        },
        [
          _c(
            "div",
            { staticClass: "modal-content", staticStyle: { overflow: "auto" } },
            [
              _c(
                "div",
                { staticClass: "modal-header" },
                [
                  _vm.title
                    ? _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("left-actions", function () {
                    return [_vm._m(0)]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm._t("actions"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-body__default" },
                    [_vm._t("default")],
                    2
                  ),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }