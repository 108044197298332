var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-collapse",
      class: [
        `btn--${_vm.size}`,
        `btn--${_vm.color}`,
        { "btn-collapse--open": _vm.open },
      ],
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm._t("prefix"),
      _vm._v(" "),
      _c("span", [_vm._t("text")], 2),
      _vm._v(" "),
      _vm._t("suffix", function () {
        return [
          _c("Icon", {
            staticClass: "btn-collapse__arrow-down",
            attrs: { id: "arrow-down", size: _vm.iconSize, color: _vm.color },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }