<template>
    <button
        class="btn btn-collapse"
        :class="[`btn--${size}`, `btn--${color}`, { 'btn-collapse--open': open }]"
        @click.prevent="$emit('click', $event)"
    >
        <slot name="prefix"></slot>

        <span><slot name="text"/></span>

        <slot name="suffix">
            <Icon
                class="btn-collapse__arrow-down"
                id="arrow-down"
                :size="iconSize"
                :color="color"
            />
        </slot>
    </button>
</template>

<script>export default {
    name: "CollapseBtn",
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'm',
            validator: (value) => ['lg', 'm', 'sm'].includes(value),
        },
        color: {
          type: String,
          default: 'default',
          validator: (value) => ['green', 'purple', 'default'].includes(value),
        },
        iconSize: {
          type: Number,
          default: 16,
        }
    },
}
</script>

<style lang="scss" scoped>
.btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    line-height: 1.25;

    &-collapse {
        border: none;
        background: transparent;

        &--open .btn-collapse__arrow-down {
            transform: rotate(180deg);
        }
    }

    .open {

    }

    &--green {
        color: #30B68F;
    }

    &--default {
      color: #232326;
    }

    &--purple {
      color: #421D57;
    }

    &--lg {
        padding: 12px 0;
        font-size: 18px;
        gap: 18px;
    }

    &--m {
        padding: 8px 0;
        font-size: 16px;
        gap: 16px;
    }

    &--sm {
        padding: 6px 0;
        font-size: 12px;
        gap: 12px;
    }
}
</style>
