import Vue from 'vue';
import Vuex from 'vuex';

import "@common/modules/vue-slider";

import "@common/scss/modules/form/_checkbox.scss";
import "@common/scss/modules/form/_checkbox-list.scss";
import "@common/scss/modules/form/_radio.scss";
import "@common/scss/modules/form/_radio-list.scss";
import "@/scss/modules/_scroll.scss";
import "@common/scss/modules/_dropdown.scss";

const pathCollapseSidebar = ['search-results', 'report', 'url-analysis'];
router()
    // Позволяет загружать модули Vue в зависимости от страницы на которой находишься
    .add('project', new RegExp('^/projects/([0-9]+)/$'))
    .add('search-results', new RegExp('^/serm-reports/project/([0-9]+)/search-results($|\/)'))
    .add('search-results', new RegExp('^/public/project/([0-9]+)/search-results($|\/)'))
    .add('report', new RegExp('^/serm-reports/project/([0-9]+)/report/$'))
    .add('report', new RegExp('^/public/project/([0-9]+)/report/$'))
    .add('wordstat', new RegExp('^/serm/project/([0-9]+)/keywords/'))
    .add('wordstat', new RegExp('^/analytics/project/([0-9]+)/segment/'))
    .add('calculator', new RegExp('^/serm/project/([0-9]+)/calculator/'))
    .add('ecom-calculator', new RegExp('^/ecom/project/([0-9]+)/ecom-calculator/$'))
    .add('trends-calculator', new RegExp('^/trends/project/([0-9]+)/trends-calculator/$'))
    .add('users', new RegExp('^/users/update/([0-9]+)/'))
    .add('users', new RegExp('^/users/create/'))
    .add('task-comment', new RegExp('^/serm/project/([0-9]+)/task/'))
    .add('topics-create', new RegExp('^/serm/project/([0-9]+)/topics/create/'))
    .add('support', new RegExp('^/support/([0-9]+)/$'))
    .add('plan', new RegExp('^/plan-tasks/templates'))
    .add('plan-project', new RegExp('^/plan-tasks/list'))
    .add('swagger', new RegExp('^/serm/project/([0-9]+)/api/$'))
    .add('url-analysis', new RegExp('^/serm-reports/project/([0-9]+)/analysis/url/([0-9]+)'))
    .add('ecom-products', new RegExp('^/ecomrev/project/([0-9]+)/tasks/([0-9]+)/([0-9]+)/([a-z]+)/'))
    .add('report-progress', new RegExp('^/report-progress/([0-9]+)/(ecom_reviews|trends)/view/'))
    .load(window.location.pathname);

export function createOwnApp(vueComponent, rootProps) {
    // const app = createApp(vueComponent, rootProps);
    window.Vue = Vue;

    window.store = new Vuex.Store();
    window.store.getRoute = getRoute;

    store.registerModule('common', require('@common/vue/store/common').default);
    store.registerModule('lang', require('@/vue/project/store/lang').default);
    store.registerModule('project', require('@/vue/project/store/project').default);
    store.registerModule('urlTags', require('@/vue/project/store/urlTags').default);

    Vue.use(store);

    Vue.component('Checkbox', Checkbox);
    Vue.component('CheckboxList', CheckboxList);
    Vue.component('Radio', Radio);
    Vue.component('RadioList', RadioList);
    Vue.component('Select', Select);
    Vue.component('Scroll', Scroll);
    Vue.component('Dropdown', Dropdown);

    Vue.component('CollapseBtn', CollapseBtn);

    Vue.component('DropdownSearch', DropdownSearch);
    Vue.component('Icon', Icon);
    Vue.component('Modal', Modal);
    Vue.component('Preloader', Preloader);
    Vue.component('ReactiveAlert', ReactiveAlert);
    Vue.component('ReactiveConfirm', ReactiveConfirm);
    Vue.component('ReactiveModal', ReactiveModal);

    Vue.component('TipMessage', require("@/vue/share/TipMessage").default);
    Vue.component('TitleBar', require("@/vue/share/TitleBar").default);

    Vue.directive('click-outside', clickOutsideEvent);
    Vue.directive('overflow-tip', overflowTooltip);
    Vue.directive('tip', tooltip);
    Vue.directive('focus', focus);

    const dialogEl = document.querySelector('#vue-dialog');
    if (dialogEl) {
        const dialogRoot = new Vue({
            el: dialogEl,
            render: h => h(Dialog),
        });

        Vue.prototype.$dialog = dialogRoot.$children[0];
    }
}

function router() {
    return {
        paths: [],

        add(page, pattern) {
            this.paths.push({page, pattern});
            return this;
        },

        async load(href) {
            for (const path of this.paths) {
                if (href.match(path.pattern)) {
                    if(pathCollapseSidebar.includes(path.page)) {
                        const body = document.getElementById('body');
                        body?.classList.add('sidebar-collapse');
                    }

                    const module = await import(`./page/${path.page}.js`);
                    createOwnApp();
                    module.default.init();
                    break;
                }
            }
        }
    }
}

import Select from "@common/vue/components/form/Select";
import Checkbox from "@common/vue/components/form/Checkbox";
import CheckboxList from "@common/vue/components/form/CheckboxList";
import Radio from "@common/vue/components/form/Radio";
import RadioList from "@common/vue/components/form/RadioList";
import Scroll from "@/vue/share/Scroll";
import Dropdown from "@common/vue/components/Dropdown";
import DropdownSearch from "@common/vue/components/DropdownSearch";
import Icon from "@/vue/share/Icon";
import CollapseBtn from "@/vue/share/CollapseBtn";
import Modal from "@/vue/share/Modal";
import Preloader from "@/vue/share/Preloader";
import ReactiveAlert from "@/vue/share/ReactiveAlert";
import ReactiveConfirm from "@/vue/share/ReactiveConfirm";
import ReactiveModal from "@/vue/share/ReactiveModal";
import clickOutsideEvent from '@common/vue/directives/clickOutsideEvent';
import overflowTooltip from '@common/vue/directives/overflowTooltip';
import tooltip from '@common/vue/directives/tooltip';
import focus from "@common/vue/directives/focus";
import Dialog from "@common/vue/components/Dialog";
import {getRoute} from "@common/vue/store/routes";
