<template>
    <div class="preloader-wrapper">
        <div
            class="spinner-border"
            role="status"
            :style="{
                height: size,
                width: size,
            }">
        </div>
    </div>
</template>

<script>
export default {
    name: "Preloader",
    props: {
        size: {
            type: String,
            default: '2rem',
        },
    },
}
</script>

<style lang="scss">
.preloader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5px;
}

.preloader-wrapper .spinner-border {
    color: #30B68F;
}
</style>
