var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "reactive-alert fade modal",
      attrs: { role: "dialog", tabindex: "", "aria-hidden": "true" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-body" }, [
            _vm.title
              ? _c("div", { staticClass: "modal-body__title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body__message" },
              [_vm._t("default")],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body__actions" },
              [_vm._t("actions")],
              2
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }