var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scroll" }, [
    _c("div", { staticClass: "simplebar-wrapper" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "simplebar-mask" }, [
        _c("div", { staticClass: "simplebar-offset" }, [
          _c(
            "div",
            _vm._g(
              {
                ref: "scrollElement",
                staticClass: "simplebar-content-wrapper",
              },
              {
                ...(_vm.$listeners.scroll && {
                  scroll: _vm.$listeners.scroll,
                }),
              }
            ),
            [
              _c(
                "div",
                { ref: "contentElement", staticClass: "simplebar-content" },
                [_vm._t("default")],
                2
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "simplebar-placeholder" }),
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "simplebar-height-auto-observer-wrapper" },
      [_c("div", { staticClass: "simplebar-height-auto-observer" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "simplebar-track simplebar-horizontal" }, [
      _c("div", { staticClass: "simplebar-scrollbar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "simplebar-track simplebar-vertical" }, [
      _c("div", { staticClass: "simplebar-scrollbar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }