<template>
    <div class="reactive-confirm fade modal" role="dialog" tabindex="" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-body__title" v-if="title" v-html="title"></div>
                    <div class="modal-body__message">
                        <slot name="default"></slot>
                    </div>
                    <div class="modal-body__actions">
                        <slot name="actions">
                            <button
                                @click="() => !confirmDisabled && $emit('confirm')"
                                class="btn btn-primary w-160"
                                :disabled="!!confirmDisabled"
                                :title="typeof confirmDisabled !== 'boolean' ? confirmDisabled : false"
                                data-dismiss="modal">{{ confirmText }}</button>
                            <button class="btn btn-secondary w-160 ml-2" data-dismiss="modal">{{ cancelText }}</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import modalMixin from "@/vue/share/modalMixin";

export default {
    mixins: [modalMixin],
    props: {
        confirmText: {
            type: String,
            default: 'Подтвердить',
        },
        confirmDisabled: {
            type: String | Boolean,
            default: '',
        },
        cancelText: {
            type: String,
            default: 'Отмена',
        },
    },
    mounted() {
        this.openModal();
    },
    beforeDestroy() {
        this.closeModal();
    }
}
</script>

<style lang="scss">
@import "/resources/scss/modules/modal/reactive-confirm";
</style>
